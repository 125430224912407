import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApplicationDataApi } from 'api/ApplicationDataApi';
import { GetApplicationDataResponse } from 'handlers/applicationData';

export const createUpdateEmploymentDataThunk = (api: ApplicationDataApi) =>
  createAsyncThunk('applicationData/updateEmploymentData', async (data: any, thunkApi) => {
    try {
      const application: GetApplicationDataResponse = await api.updateEmploymentData(
        data.applicationId,
        data.employmentData,
        data.additionalIncome,
      );
      return application;
    } catch (error: any) {
      thunkApi.rejectWithValue(error);

      throw error;
    }
  });
