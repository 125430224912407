import React, { useEffect } from 'react';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { useSelector } from 'react-redux';

import { getApplicationData as getApplicationDataThunk } from 'thunks';
import { FlowComponentType } from 'routes/FlowRouter';
import { getApplicationData } from 'selectors/getApplicationData';
import { getLoanOffer } from 'selectors/getLoanOfferData';

import Button, { ButtonType } from 'components/Button/Button';
import { GetApplicationData, HardOfferData, getShouldOfferPlanneryLoan } from 'handlers/applicationData';
import { getRemainingTerm } from 'utils/dateUtils';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { RoutePath } from 'enums/Routes';
import { ErrorType } from 'components/Error/ErrorType';
import { useNavigate } from 'react-router-dom';

import styles from './NextSteps.module.scss';

const getOfferLabels = (shouldOfferPlanneryLoan: boolean, application?: GetApplicationData): JSX.Element => {
  if (shouldOfferPlanneryLoan) {
    const offerSummary = (application?.hardOffer as HardOfferData)?.offerSummary;
    const { amount: term, timeFrame } = getRemainingTerm(offerSummary.monthsSaved);

    return (
      <div className={styles.descriptionContainer}>
        <p className={styles.header}>
          We can save you {formatMonetaryAmount(offerSummary?.moneySaved)} and get you out of debt {term} {timeFrame}{' '}
          earlier
        </p>
        <p className={styles.subtitle}>
          We analyzed your debt profile and determined that you’re pre-qualified for our debt consolidation that we
          offer exclusively to healthcare workers.
        </p>
      </div>
    );
  }

  return (
    <div className={styles.descriptionContainer}>
      <p className={styles.header}>Do you have debt?</p>
      <p className={styles.subtitle}>
        In one click, we can analyze your debt profile and show you your path out of debt.
      </p>
    </div>
  );
};

const NextSteps = ({ handleNext }: FlowComponentType) => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();
  const navigate = useNavigate();

  const offer = useSelector(getLoanOffer);
  const { application, isLoading: isLoadingApplicaiton } = useSelector(getApplicationData);

  useEffect(() => {
    if (offer.isCompleted && offer.response.data.application_id && !isLoadingApplicaiton && application === undefined) {
      dispatchWithUnwrap(getApplicationDataThunk(offer.response.data.application_id!));
    }
  }, [offer]);

  let shouldOfferPlanneryLoan = false;

  if (application !== undefined) {
    shouldOfferPlanneryLoan = getShouldOfferPlanneryLoan(application);
  }

  const handleContinue = () => {
    if (!offer.isLoading && offer.isError) {
      navigate(RoutePath.Error, { state: { type: ErrorType.CantGenerateOffer } });
    } else {
      handleNext(shouldOfferPlanneryLoan);
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Next steps</h1>
      <div>
        <div className={styles.descriptionContainer}>
          <p className={styles.subtitle}>
            While you’re waiting to get your Plannery card, we can provide other resources to you.
          </p>
        </div>
        {getOfferLabels(shouldOfferPlanneryLoan, application)}
      </div>
      <Button
        type={ButtonType.Primary}
        onClick={() => {
          handleContinue();
        }}
        className={styles.button}
      >
        {shouldOfferPlanneryLoan ? 'See How' : 'Continue'}
      </Button>
    </div>
  );
};

export default NextSteps;
