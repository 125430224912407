import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CardColor } from 'components/CardFlow/Customize/Customize';
import { ConversionType, trackConversion } from 'utils/analytics';

export interface CardData {
  applied?: boolean;
  referredBy?: string;
  referralLink?: string;
  isQualified?: boolean;
  hasAlreadyApplied?: boolean;
  cardColor?: CardColor;
  waitListPosition?: number;
}

const initialState: CardData = {
  applied: false,
};

const cardData = createSlice({
  name: 'card',
  initialState,
  reducers: {
    setCardData: (state: CardData, { payload }: PayloadAction<CardData>) => {
      if (payload.applied && !payload.hasAlreadyApplied) {
        trackConversion(ConversionType.CardApplied);
        if (payload.isQualified) {
          trackConversion(ConversionType.CardAppliedAndQualified);
        }
      }
      payload.applied !== undefined && (state.applied = payload.applied);
      payload.referredBy && (state.referredBy = payload.referredBy);
      payload.referralLink && (state.referralLink = payload.referralLink);
      payload.cardColor && (state.cardColor = payload.cardColor);
      payload.waitListPosition && (state.waitListPosition = payload.waitListPosition);
    },
  },
});

export const { setCardData } = cardData.actions;

export default cardData.reducer;
